.recommendations-page {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .p-button.import-recommendations {
      margin-bottom: 13px;
    }

    .recommendations-filter-panel {
      width: 100%;
    }
}