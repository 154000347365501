@import "../../base";

.page-wrapper.activities {
  display: flex;
  flex-direction: column;

  & > .p-button {
    margin-bottom: 8px;
    align-self: flex-end;
  }
}