@import "../../../../base";

.import-recommendations-modal {
  .p-button-text.add-text-area {
    margin-top: 15px;
    align-self: flex-start;
    width: unset;

    .p-button-label {
      flex: unset;
    }
  }
}