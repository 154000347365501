@import "../../../base";

.home-calendar {
    header {
        display: flex;
        justify-content: space-between;
        margin: 0 0 5px;

        .year {
            color: #252631;
            font-family: Rubik;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
        }

        .arrows {
            i {
                color: #778CA2;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 21px;
                text-align: center;
                position: relative;
                top: 2px;
                cursor: pointer;

                &:first-of-type {
                    left: 6px;
                }

                &:hover {
                    color: $primaryBlue;
                }
            }
        }
    }

    main {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        div {
            border-radius: 3px;
            border-radius: 4px;
            background-color: #F2F4F6;
            border: 1px solid transparent;
            position: relative;
            width: 31%;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 6px 0 3px;
            cursor: pointer;
            color: #778CA2;
            font-family: Rubik;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 21px;
            text-align: center;

            span {
                width: 5px;
                height: 5px;
                background-color: blue;
                position: absolute;
                top: -3.5px;
                right: -3.5px;
                border-radius: 9px;
                display: none;
            }

            &.current {
                background-color: #DBE9F2;
            }

            &.marked {
                span {
                    display: inline-block;
                }
            }

            &.selected, &:hover {
                border-color: $primaryBlue;
                color: $primaryBlue;
            }
        }
    }
}