.assign-to-project-modal {
  .projects-assigned {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -5px;

    .project-name-item {
      margin: 5px 0;
    }
  }
}