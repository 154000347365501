@import "../../base";

.statistics {
  .sctemp {
    width: 500px;
  }
  
  .statistics-page {
    padding: 18px 0;

    & > button.p-button-secondary {
      float: right;
      top: 8px;
      position: relative;
    }

    h2 {
      color: #252631;
      font-family: Rubik;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
      text-transform: uppercase;
      margin-bottom: 6px;
    }
  
    & > p {
      margin: 0 0 18px;
      color: #778CA2;
      font-family: Rubik;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
  
      &:last-of-type {
        margin-bottom: 25px;
      }
    }

    .sp--charts {
      display: flex;

      .sp--charts--tablist {
        width: 22%;
        margin-right: 20px;

        & > div {
          border: 1px solid #E8ECEF;
          border-radius: 4px;
          background-color: #FFFFFF;
          height: 92px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 17px 18px;
          position: relative;
          cursor: pointer;

          title {
            display: block;
            color: #778CA2;
            font-family: Rubik;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 21px;
            font-weight: 500;
            margin-bottom: 12px;
          }

          title + div {
            color: #98A9BC;
            font-family: Rubik;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 21px;
            display: flex;
            align-items: center;

            i {
              font-size: 18px;
              letter-spacing: 0;
              line-height: 21px;
              margin-right: 6px;
            }
          }

          .tab--progress-bar {
            background-color: #DBE9F2;
            height: 4px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            align-items: stretch;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            display: none;

            & > div {
              background-color: $primaryBlue;
            }
          }

          &.active {
            box-shadow: 0 2px 16px 0 rgba(153,155,168,0.23);

            .tab--progress-bar {
              display: flex;
            }
          }

          & + div {
            margin-top: 8px;
          }
        }
      }

      .sp--charts--tabcontent {
        flex: 1;
      }
    }
  }
}
