@import "../../base";

.page-wrapper.projects {
  .projects-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nrtd-filter-select {
      & + .nrtd-filter-select {
        margin-left: 25px;
      }
    }
  }

  .p-dataview {
    .p-dataview-content {
      background-color: transparent;
    }

    .p-grid {
      display: flex;
      justify-content: space-between;

      .project-item {
        height: 301px;
        width: 361px;
        width: 31.9%;
        margin: 12px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        padding: 29px 24px;

        .project-item-top-bar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: -16px;
          margin-bottom: 4px;

          .progress-bar-wrapper {
            width: 54px;
            height: 4px;
            background-color: #f2f4f6;
            display: flex;
          }
        }

        .project-item-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .project-icon {
          width: 80px;
          height: 80px;

          i {
            font-size: 36px;
          }
        }

        h2 {
          color: #252631;
          font-family: Rubik;
          font-size: 20px;
          letter-spacing: 0;
          line-height: 24px;
          text-align: center;
          margin: 19px 0 19px;
          font-weight: normal;
        }

        &.create {
          border: 1px dashed #106fa8;
          justify-content: center;

          .la-plus-circle {
            color: #106fa8;
            font-size: 26px;
            text-align: center;
          }
        }

        &:not(.create) {
          background-color: white;
        }

        &:hover {
          box-shadow: 0 2px 16px 0 rgba(153, 155, 168, 0.23);

          h2 {
            color: #106fa8;
          }
        }
      }
    }

    .p-paginator {
      background-color: transparent;
    }
  }
}
