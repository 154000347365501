.statistics-chart {
  position: relative;

  .statistics-chart--title-row {
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: -4px;
    z-index: 1;

    .statistics-chart--title {
      color: #252631;
      font-family: Rubik;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
      text-transform: uppercase;
    }
  }
}