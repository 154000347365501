@import "../../../base";

.selected-items {
  margin-bottom: 20px;
  
  title {
    display: block;
    color: #778CA2;
    font-family: Rubik;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    margin-bottom: 10px;
  }

  .selected-items--list {
    display: flex;

    .selected-items--item {
      display: flex;
      align-items: flex-start;
      cursor: pointer;

      i {
        color: #106FA8;
        font-size: 16px;
        line-height: 18px;
        margin-right: 6px;
      }

      span {
        color: #252631;
        font-family: Rubik;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        width: 227px;
        text-transform: uppercase;
      }

      & + .selected-items--item {
        margin-left: 40px;
      }

      &:hover {
        span {
          color: $primaryBlue;
        }
      }
    }
  }
}