.reporting-page {
  position: relative;

  .display-prev-cycles {
    position: absolute;
    right: 30px;
    top: 15px;
    z-index: 1;

    label {
      font-weight: 700;
      font-size: 15px;
      margin-left: 5px;
    }
  }

  .p-datatable {
    table {
      tbody.p-datatable-tbody {
        tr {
          td {
            &:first-child {
              color: #252631;
              font-family: Rubik;
              font-size: 16px;
              letter-spacing: 0;
              line-height: 24px;
            }

            &:last-child {
              width: 220px;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
