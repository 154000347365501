@import "../../base";

.settings.page-wrapper {
  padding: 0 !important;

  .settings--tab-content {
    .settings--title-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      h1 {
        color: #252631;
        font-family: Rubik;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 400;
        margin: 0;
      }

      .settings--buttons {
        display: flex;
        justify-content: flex-end;

        .search-inputgroup {
          width: auto;

          input {
            max-width: 184px;
          }

          button {
            min-width: unset;
            margin-left: 0;
          }
        }

        button {
          margin-left: 33px;
        }
      }
    }

    .p-datatable {
      table {
        thead.p-datatable-thead {
          th {
            &.name {
              width: 350px;
              font-weight: 500;
            }

            &.abbreviation {
              width: 150px;
            }

            &.actions {
              width: 100px;
              text-align: right;
            }
          }
        }
    
        tbody.p-datatable-tbody {
          tr {
            td {    
              &.name {
                width: 350px;
                font-weight: 500;
              }

              &.abbreviation {
                width: 150px;
              }
    
              &.actions {
                text-align: right;
                width: 100px;
              }
            }
          }
        }
      }
    }
  }
}