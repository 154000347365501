@import "../../../_base";

.nrtd-overview-bar {
    width: 100%;
    max-width: 940px;

    .title-bar {
        margin-bottom: 13px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        h2 {
            color: #252631;
            font-family: Rubik;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 22px;
            margin: 0;
            font-weight: normal;
        }

        a {
            color: #778CA2;
            font-family: Rubik;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 14px;
            text-transform: uppercase;
            text-decoration: none;

            &:hover {
                color: $primaryBlue;
            }
        }
    }

    .bar-wrapper {
        width: 100%;
        height: 180px;
        padding: 25px 5px 25px 20px;
        background-color: white;

        canvas {
            height: 130px;
        }
    }
}



