$primaryBlue: #106fa8;
$primaryOrange: #ffab2b;
$primaryGreen: #6dd230;
$primaryPurple: #903fc2;
$primaryRed: #fe4d55;

@mixin blue-button() {
  background-color: $primaryBlue;
  color: #ffffff;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  padding: 13px;
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: normal;

  &:hover {
    background-color: darken($primaryBlue, 5);
  }
}
@mixin gray-button() {
  background-color: #f8fafb;
  color: #778ca2;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  padding: 13px;
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: normal;

  &:hover {
    background-color: darken(#f8fafb, 3);
  }
}
@mixin scale-1-2-on-hover() {
  transition: all 1s;

  &:hover {
    transform: scale(1.2);
  }
}

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $color;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
