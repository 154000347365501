@import "../../../../base";


.new-project-modal {
    min-width: 760px;
    max-width: 1200px;
    width: 95vw;

    .p-dialog-header {
      .new-project-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .new-project-modal-title {
          color: #252631;
          font-family: Rubik;
          font-size: 20px;
          letter-spacing: 0;
          line-height: 24px;
          font-weight: normal;
          text-transform: initial;
        }

        .new-project-step {
          color: #778CA2;
          font-family: Rubik;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 21px;
          display: flex;
          align-items: center;
          padding-right: 5px;
          margin-right: 20px;
        }
      }
    }

    .p-dialog-footer {
      button {
        min-width: 150px;
      }
    }

    .new-project-modal-step-1 {
      .project-icons {
        display: flex;

        .project-icon {
          width: 38px;
          height: 38px;
          cursor: pointer;

          i {
            font-size: 18px;
            letter-spacing: 0;
            line-height: 21px;
          }

          & + .project-icon {
            margin-left: 15px;
          }

          &:hover {
            border-width: 2px;
            border-style: solid;
          }
        }
      }
    }

    .new-project-modal-step-2 {
      .project-items-tabs {
        margin-bottom: 23px;
      }
    }

    &.step-1 {
      max-width: 760px;
    }
}
