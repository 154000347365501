@import "../../../base";

.nrtd-updates {
  .nrtd-updates-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0;

    .update-item-img-content {
      display: flex;

      img {
        width: 36px;
        height: 36px;
        margin-right: 18px;
      }
  
      .update-item-content {
        padding-top: 6px;

        .update-item-name-action {
          display: flex;
  
          .update-item-name {
            color: #252631;
            font-family: Rubik;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            margin-right: 9px;
            font-weight: 600;
          }
  
          .update-item-action {
            color: #778CA2;
            font-family: Rubik;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 24px;
          }
        }
  
        .update-item-property {
          margin-top: 10px;
          border-left: 3px solid #F2F4F6;
          padding-left: 15px;
  
          .property-name {
            color: #98A9BC;
            font-family: Rubik;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 21px;
          }
  
          .property-value {
            margin-top: 4px;
            color: #252631;
            font-family: Rubik;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 21px;
          }
        }
  
        .update-item-details {
          margin-top: 10px;
          color: #98A9BC;
          font-family: Rubik;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 21px;
        }
      } 
    }

    .update-item-date-actions {
      padding-top: 1px;

      & > span {
        color: #000000;
        font-family: Rubik;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        margin-right: 9px;
      }

      .action-menu {
        position: relative;
        top: 4px;
      }
    }

    & + .nrtd-updates-item {
      margin-top: 10px;
    }
  }

  .p-editor-container {
    margin-top: 40px;
    
    .p-editor-toolbar {
      .ql-header, .ql-font {
        display: none;
      }
    }
  }

  .submit-update {
    margin-top: 20px;
  }
}