@import "../../../base";

.page-wrapper.activity {
  .single-element-content {
    .details-content {
      .p-formgrid {
        margin-top: 20px;

        label[for="implementation-status"] {
          color: #252631;
          font-family: Rubik;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 24px;
        }
      }
    }
  }
}