@import "../../../../base";

.single-element-tab-content.details-content {
  .two-calendar {
    display: flex;
    flex-wrap: wrap;

    label {
      width: 100%;
    }

    .p-calendar {
      max-width: 280px;

      & + .p-calendar {
        margin-left: 13px;
      }
    }
  }

  .p-button.submit-button {
    width: 100px;
    justify-content: center;
  }
}
