@import "../../../base";

.p-toolbar {
    flex: 1;
    border-left: none;
    border-bottom: 1px solid #dee2e6;
    border-radius: 0;
    background-color: white;
    padding: 10px 26px;

    .p-toolbar-group-left {
        color: #252631;
        font-family: Rubik;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 24px;

        i.la-bars {
            margin-right: 20px;
            cursor: pointer;
            position: relative;
            top: 1.5px;
            color: #778CA2;
            font-size: 22px;
            letter-spacing: 0;
            line-height: 26px;
            text-align: center;
    
            &:hover {
                color: blue;
            }
        }
    }

    .p-toolbar-group-right {
      .search-inputgroup {
        margin-right: 25px;
      }

      i.la-bell.p-overlay-badge {
        font-size: 22px;

        .p-badge {
          top: 2px;
          right: 6px;
        }
      }

      i.la-bell.p-overlay-badge + a {
        margin-left: 25px;
        text-decoration: none;
      }

        // .p-inputgroup {
        //     .p-inputgroup-addon {
        //         border: none;
        //         background-color: transparent;

        //         i {
        //             color: #778CA2;
        //             font-size: 22px;
        //             letter-spacing: 0;
        //             line-height: 26px;
        //             text-align: center;
        //         }
        //     }

        //     input {
        //         background-color: transparent;
        //         border: none;
        //     }

        //     button {
        //         background-color: transparent;
        //         border: none;
        //         padding-left: 0;
        //         padding-right: 0;

        //         i {
        //             color: $primaryBlue;
        //             font-size: 22px;
        //             letter-spacing: 0;
        //             line-height: 26px;
        //             text-align: center;
        //         }
        //     }
        // }
    }
}

.p-overlaypanel {
    margin-top: 15px;
    box-shadow: 0 2px 16px 0 rgba(153,155,168,0.23);
    border-radius: 4px;
    width: 320px;

    button.p-overlaypanel-close {
        width: 1rem;
        height: 1rem;
        top: -0.5rem;
        left: -0.5rem;
        right: unset;
        background-color: #70869D;

        .p-overlaypanel-close-icon {
            font-size: 8px;
        }

        &:hover {
            background-color: $primaryBlue;
        }
    }

    .p-overlaypanel-content {
        padding: 0.5rem;
    }

    &.search-results {
        padding: 11px 16px;

        .p-overlaypanel-content {
            padding: 0;

            .result-block {
                border-bottom: 1px solid lightgray;
                padding: 5px 0 5px;
                margin-bottom: 11px;
    
                header {
                    display: flex;
                    justify-content: space-between;
    
                    .title {
                        color: #252631;
                        font-family: Rubik;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 26px;
                        display: flex;

                        i {
                            margin-right: 10px;
                            color: #778CA2;
                            font-size: 22px;
                            letter-spacing: 0;
                            line-height: 26px;
                            text-align: center;
                        }
                    }

                    span {
                        color: #252631;
                        font-family: Rubik;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 21px;
                    }
                }

                main {
                    .result {
                        display: flex;
                        justify-content: space-between;
                        margin: 13px 0;

                        a {
                            color: #778CA2;
                            font-family: Rubik;
                            font-size: 14px;
                            letter-spacing: 0;
                            line-height: 22px;
                            text-decoration: none;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 90%;
                            height: 22px;
    
                            &:hover {
                                color: $primaryBlue;
                            }
                        }
                    }
                }

                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    &:before, &:after {
        display: none;
    }
}