.nrtd-filter-select {
    position: relative;
    display: inline-block;
    color: #778CA2;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    text-transform: uppercase;

    i {
        color: #778CA2;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
        position: relative;
        top: 2px;

        &:first-child {
            margin-right: 3px;
        }
    }

    .p-dropdown {
        background-color: transparent;
        border: none;

        .p-dropdown-label {
            color: #252631;
            font-family: Rubik;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 14px;
            padding-right: 3px;
            padding-top: 0;
            padding-bottom: 0;
            max-width: 350px;
            text-overflow: ellipsis;
        }

        .p-dropdown-trigger {
            width: auto;
            padding-left: 4px;
            position: relative;
            top: -2px;

            .p-dropdown-trigger-icon {
                color: #778CA2;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 18px;
                text-align: center;
            }
        }

        &.p-inputwrapper-focus {
            box-shadow: none;
        }
    }
}

.filter-select-panel {
  background: white;
  box-shadow: 0 2px 16px 0 rgba(153,155,168,0.23);
  border-radius: 4px;

  .p-dropdown-items {
    padding: 0;

    .p-dropdown-item {
      color: #252631;
      font-family: Rubik;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
      padding: 8px;
      text-transform: uppercase;
      max-width: 400px;
      text-overflow: ellipsis;
    }
  }
}