@import "../../../base";

.page-wrapper.recommendation {
  .single-element-content {
    .details-content {
      width: 73%;
      
      .title {
        max-width: unset;
      }
    }
  }
}