@import "../../../../base";

.single-element-tab-content.details-content {
  p.mechanism-title {
    color: #778CA2;
    font-family: Rubik;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin: 0 0 8px;
  }
  
  a.date-string {
    color: #252631;
    font-family: Rubik;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 15px;
    display: block;

    i {
      color: #106FA8;
      font-size: 18px;
    }
  }

  .recommendation-properties {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }

  .recommendation-sdgs {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 25px;

    .recommendation-sdg--title {
      color: #98A9BC;
      font-family: Rubik;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 10px;
      width: 100%;
    }

    .sdg-column {
      width: 96px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      img {
        max-width: 100%;
      }

      .sub-sdg {
        height: 21px;
        width: 48px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        margin-top: 8px;
        font-size: 16px;
        line-height: 16px;
      }
  
      & + .sdg-column {
        margin-left: 18px;
      }
    }
  }

  .p-button.submit-button {
    width: 100px;
  }
}
