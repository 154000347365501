@import "../../base";

.page-wrapper.home {
    display: flex;
    justify-content: space-between;

    .home-content {
        width: 65%;

        h1 {
            color: #252631;
            font-family: Rubik;
            font-size: 26px;
            letter-spacing: 0;
            line-height: 31px;
            margin: 10px 0 3px;
            font-weight: normal;
        }

        p {
            color: #778CA2;
            font-family: Rubik;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 21px;
            padding: 0;
            margin: 5px 0;

            &:last-of-type {
                margin-bottom: 27px;
            }
        }
        
        .nrtd-overview-bar {
            margin-top: 20px;
        }
    }

    .home-sidepanel {
        width: 32%;

        h2 {
            color: #252631;
            font-family: Rubik;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 22px;
            font-weight: normal;
            margin: 0 0 13px;
        }

        .p-card {
            padding: 0;

            .p-card-body {
                padding: 0;

                .p-card-content {
                    padding: 26px 24px;

                    .home-calendar + hr {
                        margin: 20px 0 15px;
                        border: none;
                        border-bottom: 1px solid #E8ECEF;
                    }

                    .selected-month {
                        color: #778CA2;
                        font-family: Rubik;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 21px;
                        display: flex;
                        margin-bottom: 10px;

                        i {
                            color: #778CA2;
                            font-size: 18px;
                            letter-spacing: 0;
                            line-height: 21px;
                            text-align: center;
                            margin-right: 6px;
                        }
                    }

                    .home--sidepanel--list {
                        header {
                            color: #252631;
                            font-family: Rubik;
                            font-size: 12px;
                            letter-spacing: 0;
                            line-height: 18px;
                            // margin-bottom: 3px;
                        }

                        .list-item {
                          display: flex;
                          flex-direction: column;

                            a {
                                color: #252631;
                                font-family: Rubik;
                                font-size: 16px;
                                letter-spacing: 0;
                                line-height: 24px;
                                text-decoration: none;

                                &:hover {
                                    color: $primaryBlue;
                                }
                            }

                            .list-item--event {
                              color: #252631;
                              font-family: Rubik;
                              font-size: 14px;
                              font-weight: 300;
                              letter-spacing: 0;
                              line-height: 21px;
                            }

                            & + .list-item {
                              margin-top: 10px;
                            }
                        }

                        &.projects {
                          header {
                            color: $primaryBlue;
                          }
                        }

                        &.activities {
                          header {
                            color: $primaryGreen;
                          }
                        }

                        & + .home--sidepanel--list {
                          margin-top: 18px;
                        }
                    }

                    .p-button.create-event.p-button-text {
                      margin-top: 30px;
                      text-transform: initial;
                      
                      .p-button-label {
                        font-family: Rubik;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 22px;
                      }
                    }
                }
            }
        }        
    }
}