@import "../../base";

.page-wrapper.profile {
  .profile-form {
    max-width: 750px;

    .p-field {
      margin-bottom: 20px;
    }

    .p-button.save-button {
      width: auto;
    }

    .p-button.change-password-button {
      width: auto;
      margin: 6px 0;
    }
  }
}