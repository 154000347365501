@import "../../../../base";

.report.details-content {
  h2 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #252631;
    margin: 0 0 15px;
  }

  p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #778ca2;
    margin: 0;

    & + p {
      margin-top: 15px;
    }
  }

  .report-points {
    .point {
      margin-top: 30px;
      border-top: 1px solid #e8ecef;
      padding-top: 32px;

      .action-menu {
        float: right;
      }

      h3 {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #252631;
        margin: 0 0 13px;
      }

      h4 {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #252631;
      }

      p {
        strong {
          font-weight: 400;
          color: black;
        }
      }

      .p-editor-container {
        margin-top: 20px;

        .p-editor-toolbar {
          .ql-header,
          .ql-font {
            display: none;
          }
        }
      }

      .point__comment-char-num {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        top: -32px;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        text-align: right;
        color: #98a9bc;
        padding-right: 24px;
        margin-bottom: -21px;
      }

      .point-buttons {
        margin-top: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .recommendation-collapse-trigger {
          i {
            margin-left: 5px;
            color: #778ca2;
          }
        }
      }

      .linked-recommendations-collapse {
        transition: 0.3s height;

        .linked-recommendations-wrapper {
          padding-top: 34px;

          header {
            display: block;
            color: #778ca2;
            font-family: Rubik;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 17px;
            margin-bottom: 12px;
          }

          .recommendations {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 15px;

            .recommendation {
              position: relative;

              .item-content {
                display: flex;
                align-items: center;

                i {
                  color: #903fc2;
                  background-color: #f2e8f8;
                  border-radius: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex: 0 0 30px;
                  height: 30px;
                  margin-right: 10px;
                }

                span {
                  max-height: 42px;
                  overflow: hidden;
                  color: #252631;
                  font-family: Rubik;
                  font-size: 12px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 14px;
                  text-transform: uppercase;
                  max-width: 162px;
                }
              }

              .remove-button {
                position: absolute;
                top: 0;
                right: 0;
                width: 73px;
                height: 100%;
                display: none;
                justify-content: flex-end;
                padding-right: 14px;
                align-items: center;
                z-index: 2;
                cursor: pointer;
                background: linear-gradient(
                  90deg,
                  rgba(255, 255, 255, 0.5) 0%,
                  #ffffff 100%
                );
              }

              &:hover {
                .remove-button {
                  display: flex;
                }
              }
            }
          }

          .p-button-text.link-recommendation {
            margin-top: 17px;
          }
        }
      }

      &:last-child {
        border-bottom: 1px solid #e8ecef;
        padding-bottom: 32px;
        margin-bottom: 30px;
      }
    }
  }

  .p-button.p-button-text.add-more {
    text-transform: capitalize;
    font-size: 14px;
  }
}
