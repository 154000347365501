@import "../../../../base";

.single-element-sidepanel.report {
  .p-button {
    & + .p-button {
      margin-top: 7px;
    }
  }

  .report__panel-block {
    margin-top: 35px;

    title {
      display: block;
      color: #778ca2;
      font-family: Rubik;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      margin-bottom: 8px;
    }

    &.lead-institution {
      .p-field {
        margin-bottom: 0;

        .p-dropdown {
          .p-dropdown-label {
            width: 184px;
          }
        }
      }
    }

    &.respond-by {
      label {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #252631;
        display: block;
        margin-top: -5px;
      }
    }

    &.status {
      .status-wrapper {
        display: flex;
        align-items: center;

        label {
          margin-left: 7px;
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          text-transform: uppercase;
          color: #252631;
        }
      }
    }

    &.word-limit {
      label {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        color: #98a9bc;
        display: block;
      }
    }

    &:last-of-type {
      margin-bottom: 31px;
    }
  }

  .p-button.p-button-text {
    text-transform: capitalize;
    text-align: left;
    font-size: 14px;

    & + .p-button.p-button-text {
      margin-top: 27px;
    }
  }
}
