@import "../../../base";

.mechanism-reporting-list {
  margin-top: 16px;

  .p-dataview-content {
    padding-left: 0;
    padding-right: 0;
  }

  .mechanism {
    padding-top: 18px;
    padding-bottom: 18px;

    .mechanism-title-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .collapse-trigger-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 1;

        .mechanism-title {
          font-size: 18px;
          line-height: 21px;
          font-family: "Rubik";
          padding-right: 10px;
        }
      }

      .collapse-trigger-arrow {
        margin-left: 10px;
      }
    }

    .mechanism-collapse {
      transition: height 0.3s;

      .mechanism-content {
        padding-top: 20px;

        .reporting-procedure {
          .property-field-value {
            font-weight: 500;
            text-transform: uppercase;
            font-size: 12px;
            padding: 6px 0;
            display: flex;
            align-items: center;

            i {
              color: #778ca2;
              font-size: 18px;
              margin-left: 3px;
            }
          }
        }

        .p-datatable {
          margin-top: 10px;

          thead {
            th {
              border-bottom: 2px solid #e8ecef;

              &:first-child {
                padding-left: 0;
              }
            }
          }

          .p-datatable-tbody {
            tr {
              td {
                font-size: 14px;
                letter-spacing: 0.3px;
                padding-top: 6px;
                padding-bottom: 6px;
                border-bottom: 1px solid #e8ecef;

                .p-field {
                  margin-bottom: 0;

                  .p-dropdown {
                    .p-dropdown-label {
                      width: 200px;
                    }
                  }
                }

                .mechanism-buttons {
                  width: 280px;
                  display: flex;
                  justify-content: space-between;

                  .blue-button {
                    padding: 10px 13px;
                  }
                }
              }

              &:nth-child(2n + 1) {
                td {
                  background-color: #f8fafb;
                }
              }

              &:nth-child(2n) {
                td {
                  background-color: #fff;
                }
              }
            }
          }
        }

        .view-buttons {
          margin-top: 28px;

          button {
            &.p-button-text {
              text-transform: unset;
              font-size: 14px;
              font-weight: 400;

              .p-button-icon {
                font-size: 16px;
              }
            }

            & + button {
              margin-left: 30px;
            }
          }
        }
      }
    }

    & + .mechanism {
      border-top: 2px solid #e8ecef;
    }
  }
}
