.settings-tabs {
  display: flex;
  justify-content: center;

  ul.settings-tabs--tablist {
      list-style-type: none;
      width: 293px;
      margin: 0;
      padding-left: 0;

      li {
          display: flex;
          align-items: flex-start;
          padding: 14px 25px;
          border-bottom: 1px solid #F2F4F6;
          border-right: 1px solid #F2F4F6;
          cursor: pointer;

          i {
            color: #778CA2;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 23px;
            text-align: center;
            margin-right: 16px;
          }

          h3 {
            color: #252631;
            font-family: Rubik;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 21px;
            margin: 0;
          }

          h4 {
            color: #98A9BC;
            font-family: Rubik;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
            font-weight: 400;
            margin: 0;
          }
          
          &.active {
            border-right: 2px solid #106FA8;

              i {
                color: #106FA8;
              }

              h3 {
                color: #106FA8;
              }
          }

          &:hover {
              background-color: #ddd;
          }
      }
  }

  .settings-tab-content {
      display: none;
      padding: 25px 29px;
      flex: 1;

      &.active {
          display: block;
      }
  }

  @media (max-width: 800px) {
      ul.settings-tabs--tablist {
          li {
              padding: 10px 13px;

              i {
                  margin-right: 13px;
              }

              .title-subtitle {
                  h3 {
                      font-size: 16px;
                      margin-bottom: 5px;
                  }

                  h4 {
                      font-size: 14px;
                      margin: 0;
                  }
              }
          }
      }
  }

  @media (max-width: 630px) {
      flex-direction: column;

      ul.settings-tabs--tablist {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: stretch;
          list-style-type: none;

          li {
              width: 25%;
              display: block;
              box-sizing: border-box;

              i {
                  float: left;
                  margin-right: 8px;
              }

              .title-subtitle {
                  h3 {
                      margin-left: 28px;
                      font-size: 14px;
                  }

                  h4 {
                      font-weight: normal;
                      font-size: 14px;
                      line-height: 16px;
                  }
              }

              &.active {
                  background-color: rgba(0, 0, 0, 0.05);
                  border-right: 1px solid #ddd;
                  border-bottom: 2px solid blue;
              }
          }
      }

      .settings-tab-content {
          // width: 100%;
      }
  }

  @media (max-width: 500px) {
      ul.settings-tabs--tablist {
          li {
              width: 33.33%;
          }
      }
  }
}