@import "../../../base";

.p-dropdown.action-menu {
  width: 34px;
  background-color: transparent;
  border: none;

  .p-dropdown-label {
    display: none;
  }

  .p-dropdown-trigger {
    .la-ellipsis-h {
      color: #778ca2;
      font-size: 22px;
      line-height: 26px;
    }
  }
}
.p-dropdown-panel.action-menu-panel {
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgba(153, 155, 168, 0.23);

  ul.p-dropdown-items {
    padding-top: 0;
    padding-bottom: 0;

    li.p-dropdown-item {
      padding-top: 14px;
      padding-bottom: 14px;

      &:first-child {
        padding-top: 20px;
      }

      &:last-child {
        padding-bottom: 20px;
      }

      .action-item {
        display: flex;
        align-items: center;
        color: #252631;
        font-family: Rubik;
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
        letter-spacing: 0;
        text-transform: uppercase;

        i {
          color: #778ca2;
          font-size: 18px;
          margin-right: 6px;
          position: relative;
          top: -1px;
        }
      }

      &:hover:not(.p-highlight):not(.p-disabled) {
        background-color: transparent;

        .action-item {
          color: $primaryBlue;

          i {
            color: $primaryBlue;
          }
        }
      }
    }
  }

  &.blue-links {
    ul.p-dropdown-items {
      li.p-dropdown-item {
        .action-item {
          color: $primaryBlue;

          i {
            color: $primaryBlue;
          }
        }
      }
    }
  }
}
